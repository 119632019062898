export default [
	{
		path: '/institutionManager',
		name: 'institutionManager',
		meta: {
			title: '机构管理',
			icon: 'iconfont icon-jigouguanli1',
		},
		children: [
			{
				path: '/institutionManager/hospitalInfo',
				name: 'hospitalInfo',
				meta: {
					title: '医院信息管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			// {
			// 	path: '/institutionManager/departmentManager',
			// 	name: 'departmentManager',
			// 	meta: {
			// 		title: '科室管理',
			// 		icon: 'iconfont icon-fuzhiyemian',
			// 	},
			// },
		],
	},
	{
		path: '/devicesResource',
		name: 'devicesResource',
		meta: {
			title: '设备资源管理',
			icon: 'iconfont icon-shebei',
		},
		children: [
			{
				path: '/devicesResource/manufacturer',
				name: 'manufacturer',
				meta: {
					title: '厂家字典',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/devicesResource/diseaseManager',
				name: 'diseaseManager',
				meta: {
					title: '病症查询关联',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/devicesResource/deviceManager',
				name: 'deviceManager',
				meta: {
					title: '设备管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/devicesResource/diagnosisCaseManager',
				name: 'diagnosisCaseManager',
				meta: {
					title: '诊疗方案',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/devicesResource/configManager',
				name: 'configManager',
				meta: {
					title: '配置管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/devicesResource/useHistory',
				name: 'useHistory',
				meta: {
					title: '使用记录',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
		],
	},
	{
		path: '/mobilResourceManagement',
		name: 'mobilResourceManagement',
		meta: {
			title: '移动资源管理',
			icon: 'iconfont icon-ziyuantongji',
		},
		children: [
			{
				path: '/mobilResourceManagement/benefitingInfo',
				name: 'benefitingInfo',
				meta: {
					title: '惠民信息',
				},
			},
			{
				path: '/mobilResourceManagement/healthCounseling',
				name: 'healthCounseling',
				meta: {
					title: '健康资讯',
				},
			},
			{
				path: '/mobilResourceManagement/traditionalInfo',
				name: 'traditionalInfo',
				meta: {
					title: '中药信息（中药养生）',
				},
			},
			{
				path: '/mobilResourceManagement/healthCareProduct',
				name: 'healthCareProduct',
				meta: {
					title: '保健产品',
				},
			},
			{
				path: '/mobilResourceManagement/healthEquipment',
				name: 'healthEquipment',
				meta: {
					title: '康养设备',
				},
			},
		],
	},
	{
		path: '/mobileCustomerService',
		name: 'mobileCustomerService',
		meta: {
			title: '移动客户服务',
			icon: 'iconfont icon-yidongkehufuwu',
		},
		children: [
			{
				path: '/mobileCustomerService/helpAndConsultation',
				name: 'helpAndConsultation',
				meta: {
					title: '求助与咨询',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			// {
			// 	path: '/mobileCustomerService/healthProduct',
			// 	name: 'healthProduct',
			// 	meta: {
			// 		title: '健康产品',
			// 		icon: 'iconfont icon-fuzhiyemian',
			// 	},
			// },
			// {
			// 	path: '/mobileCustomerService/healthEquipment',
			// 	name: 'healthEquipment',
			// 	meta: {
			// 		title: '康养设备',
			// 		icon: 'iconfont icon-fuzhiyemian',
			// 	},
			// },
		],
	},
	{
		path: '/chineseMedicineService',
		name: 'chineseMedicineService',
		meta: {
			title: '中医服务',
			icon: 'iconfont icon-zhongyifuwu',
		},
		children: [
			{
				path: '/chineseMedicineService/infoManager',
				name: 'infoManager',
				meta: {
					title: '中医信息管理',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
			{
				path: '/chineseMedicineService/imgTextService',
				name: 'imgTextService',
				meta: {
					title: '中医图文服务',
					icon: 'iconfont icon-fuzhiyemian',
				},
			},
		],
	},
];
